import "./main.scss";
import "./ui_main_alert_confirm.html";
import "./UI_MAIN_001.html";
import "./UI_MAIN_001_01.html";

import "./UI_GNB_002_01.html";
import "./UI_GNB_002_02.html";
import "./UI_GNB_002_04.html";
import "./UI_GNB_002_05.html";

import "./UI_GNB_003.html";
import "./UI_GNB_003_01.html";
import "./UI_GNB_003_02.html";
import "./UI_GNB_003_03.html";
import "./UI_GNB_003_04.html";
import "./UI_GNB_003_04_1.html";

import "./UI_GNB_004.html";
import "./UI_GNB_004_01.html";
import "./UI_GNB_004_02.html";
import "./UI_GNB_004_03.html";
import "./UI_GNB_004_08.html";
import "./UI_GNB_004_09.html";
import "./UI_GNB_004_17.html";
import "./UI_GNB_004_18.html";
import "./UI_GNB_004_21.html";
import "./UI_GNB_004_23.html";
import "./UI_GNB_004_26.html";
import "./UI_GNB_004_26_2.html";

import "./UI_GNB_005_01.html";
import "./UI_GNB_005_02.html";

import "./UI_GNB_006.html";
import "./UI_GNB_007.html";
import "./UI_GNB_006_01.html";
import "./UI_MY_001_01.html";
import "./UI_MY_001_03.html";
import "./UI_MY_001_05.html";
import "./UI_MY_001_08.html";

import "./UI_MY_001_09.html";
import "./UI_MY_001_12.html";

import "./UI_MY_002_01.html";
import "./UI_MY_002_03.html";
import "./UI_MY_002_08.html";
import "./UI_MY_002_09.html";
import "./UI_MY_002_16.html";
import "./UI_MY_002_20.html";
import "./UI_MY_002_24.html";
import "./UI_MY_002_25.html";
import "./UI_MY_002_26.html";
import "./UI_MY_002_27.html";
import "./UI_MY_002_29.html";
import "./UI_MY_002_31.html";
import "./UI_MY_003_08.html";
import "./UI_MY_003_09.html";
