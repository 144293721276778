// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./assets/images/close_30.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./assets/images/application-completed.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("./assets/images/payment-completed-fail.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<!DOCTYPE html>\r\n<html>\r\n<head>\r\n  <meta charset=\"utf-8\" />\r\n  <meta http-equiv=\"X-UA-Compatible\" />\r\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\" />\r\n</head>\r\n<body>\r\n<div class=\"wrapper\">\r\n</div>\r\n<div class=\"dimmed-cover\">\r\n  <div class=\"modal pc-modal\">\r\n    <div class=\"pc-modal-header\">\r\n      <div class=\"title\">\r\n      </div>\r\n      <button><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/></button>\r\n    </div>\r\n    <!-- 원서 접수 완료 -->\r\n    <div class=\"pc-modal-body payment-completed h-[27.4rem]\" style=\"display: none\">\r\n      <div class=\"completed-container\">\r\n        <div class=\"title\">원서 접수가 완료되었어요!</div>\r\n        <div class=\"text\">내가 접수한 대학을 확인하러 가볼까요?</div>\r\n        <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" />\r\n      </div>\r\n      <div class=\"button-container\">\r\n        <button class=\"primary-button w-full\">내가 지원한 대학 보기</button>\r\n      </div>\r\n    </div>\r\n\r\n    <!-- 티켓 구매 실패 -->\r\n    <div class=\"pc-modal-body payment-completed h-[27.4rem]\" style=\"display: block;\">\r\n      <div class=\"completed-container\">\r\n        <div class=\"title\">티켓 구매에 실패했어요...</div>\r\n        <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" />\r\n      </div>\r\n      <div class=\"button-container\">\r\n        <button class=\"primary-button w-full\">다시 시도하기</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  </div>\r\n</div>\r\n</body>\r\n</html>\r\n";
// Exports
export default code;